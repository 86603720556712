import { domReady, getAttribute, qsa } from 'lib/dom.js'

// We're going to use this to do a little checking in a second...
const location = window.location

function anchorMatchesLocation(anchor) {
	return getAttribute(anchor, 'href') !== '#'
		&& anchor.host === location.host
		&& anchor.pathname === location.pathname
}

domReady(() => {
	// Shore up 'active' links with a class.
	//	 We only check certain areas for active links.
	qsa('[data-highlight-active]').forEach(container => {
		qsa('a', container).forEach(anchor => {
			if (anchorMatchesLocation(anchor) && !anchor.matches('[data-no-highlight]')) {
				anchor.classList.add('active')
			}
		})
	})

	// Like [data-highlight-active] but matches path segments instead of the whole thing.
	qsa('[data-highlight-segment]').forEach(anchor => {
		const segments = getAttribute(anchor, 'data-highlight-segment').split(',')

		segments.forEach(segment => {
			if (anchor.host === location.host && location.pathname.match('^' + segment)) {
				anchor.classList.add('active')
			}
		})
	})
})
